import { useEffect, useState } from 'react';
import { getExperiments } from 'src/api/get-experiments';
import { getEntityId } from 'src/checkout-frame/api/get-entity-id';
import { AsyncRequestStatusType } from 'src/types/async-request-status';
import { Experiment, ExperimentParams } from 'src/types/experiment';

export const useExperiments = ({
  publicKey,
  merchantCode,
  currency,
}: ExperimentParams) => {
  const [experiments, setExperiments] = useState<{
    experiments: Experiment[];
    status: AsyncRequestStatusType;
  }>({ experiments: [], status: 'idle' });

  useEffect(() => {
    let isCanceled = false;

    (async () => {
      setExperiments((prev) => ({ ...prev, status: 'pending' }));

      const entityId = await getEntityId();

      const data = await getExperiments({
        entity: 'customer',
        platform: 'web',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        merchant_id: publicKey,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        merchant_code: merchantCode,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        entity_id: entityId,
        currency,
      });

      if (!isCanceled) {
        setExperiments({
          experiments: data,
          status: 'done',
        });
      }
    })();

    return () => {
      isCanceled = true;
    };
  }, [publicKey, merchantCode, currency]);

  return experiments;
};
